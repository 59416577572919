<template>
  <register-container fluid fullHeight>
    <div class="welcome-wrapper d-flex align-center justify-center pt-3">
      <div class="welcome-content px-3 pb-8 pb-md-0">
        <v-row class="mt-0 mt-md-10">
          <v-col cols="12" md="7">
            <div
              class="logo-header-column d-flex"
              :class="this.$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-start'"
            >
              <div><v-img :src="logo" contain max-height="150" /></div>
            </div>
            <div class="px-0 px-sm-4 px-lg-16">
              <h1 class="text-xs-h3 text-sm-h3 font-weight-regular mb-10 mb-sm-12 mt-4 mt-md-10">
                We're glad we could help
              </h1>
              <div class="mb-6">
                We’ll send you a confirmation email and text of your appointment. Please note, we
                may have to move your appointment if our schedule changes.
              </div>
              <div class="mb-6">
                Not to worry, we’ll definitely let you know. If you have any additional concerns or
                questions, please call us at <span class="font-weight-bold">1-705-328-2019</span>.
                If there is a bit of wait, you can always leave us a voice message. You may leave
                this page now.
              </div>
              <div class="mb-6">
                Alternatively, you can learn more about Sentry Health at the link below.
              </div>
              <div
                class="d-flex justify-center pt-8"
                :class="this.$vuetify.breakpoint.smAndUp ? 'flex-row' : 'flex-column'"
              >
                <div
                  class="buttons d-flex justify-space-between"
                  :class="this.$vuetify.breakpoint.xsOnly ? 'flex-column' : 'flex-row'"
                >
                  <hover-button
                    :block="this.$vuetify.breakpoint.xsOnly"
                    :small="this.$vuetify.breakpoint.smOnly"
                    :disabled="loading"
                    @click="$emit('next')"
                    customHeight="60px"
                    :classes="'my-1 my-sm-0'"
                    ><span class="font-weight-bold pa-3">Start Over</span></hover-button
                  >
                  <hover-button
                    :block="this.$vuetify.breakpoint.xsOnly"
                    :small="this.$vuetify.breakpoint.smOnly"
                    :disabled="loading"
                    @click="visitPage"
                    customHeight="60px"
                    ><span class="font-weight-bold pa-3">Visit Sentry Health</span></hover-button
                  >
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="5" v-if="this.$vuetify.breakpoint.mdAndUp">
            <v-img :src="image" class="side-image" position="right center" />
          </v-col>
        </v-row>
      </div>
    </div>
  </register-container>
</template>
<style scoped>
.choice-container {
  width: 100%;
}
.image-small {
  max-width: 250px;
}
.side-image {
  height: 100%;
}
@media all and (min-width: 600px) {
  .buttons {
    width: 100%;
  }
  .welcome-wrapper {
    height: 90%;
  }
}
@media all and (min-width: 1264px) {
  .welcome-content {
    width: 80%;
    min-width: 1200px;
    max-width: 1600px;
  }
}
</style>
<script>
/* eslint-disable global-require */
import HoverButton from '../components/HoverButton.vue';
import RegisterContainer from '../components/RegisterContainer.vue';

export default {
  name: 'RegisterGoodbye',
  components: { HoverButton, RegisterContainer },
  data() {
    return {
      image: require('../images/end_photo.jpg'),
      loading: false,
      logo: require('../images/SentryHealth-LogoV2.png'),
    };
  },
  methods: {
    async goNext(isReturningPatient) {
      await this.$store.commit('setRegistration', { isReturningPatient });
      this.$emit('next');
    },
    visitPage() {
      window.open('https://www.sentryhealth.ca', '_blank');
    },
  },
};
</script>
